var Giant = Giant || {};

;(function($, window, document, undefined) {

    var pluginName = 'giantTreeSelector',
        dataPrefix = 'treeSelector',
        $window = $(window),
        defaults = {
            separator: '&gt;',
            template: '<div class="tree-selector">\
                            <ul class="tree-selector__list"></ul>\
                            <button type="button" class="btn btn-warning btn-xs tree-selector__edit"><span class="fa fa-edit"></span></button>\
                        </div>',
            selectedItemTemplate: '<li class="tree-selector__item">{path} <input type="hidden" name="{name}" value="{id}"></li>',
            emptyTemplate: '<li class="tree-selector__item tree-selector__item--placeholder">{placeholder}<input type="hidden" name="{name}" value=""></li>'
        };

    // helpers
    function getHtmlList(nodes, data) {
        var buffer = '<ul>';

        nodes.forEach(function(item) {
            var collapsible = !!item.nodes.length;
            var classes = [];

            if (!!item.nodes.length) {
                classes.push('is-collapsible');
                classes.push('is-collapsed');
            }

            if (data[item.id].selected) {
                classes.push('is-checked');
            }

            var node = substitute('<li class="{classes}" data-id="{id}">', {
                classes: classes.join(' '),
                id: item.id
            });

            node += '<span class="option-tree__label">';

            node += '<span class="option-tree__toggle"><span class="fa fa-caret-right"></span>' + data[item.id].text + '</span>';

            node += '<span class="option-tree__checkbox"></span>';

            node += '</span>';

            if (item.nodes.length) {
                node += getHtmlList(item.nodes, data);
            }

            node += '</li>';
            buffer += node;
        });

        buffer += '</ul>';

        return buffer;
    }

    function getPath(id, data) {
        var path = [];
        while (id) {
            path.push(data[id].text);
            id = data[id].parentId;
        }
        return path.reverse().join(' > ');
    }

    function substitute(str, sub) {
        return str.replace(/\{(.+?)\}/g, function($0, $1) {
            return $1 in sub ? sub[$1] : $0;
        });
    }

    function camelCase(str) {
        return String(str).replace(/-\D/g, function(match){
            return match.charAt(1).toUpperCase();
        });
    }

    function escapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }

    function CheckboxTree(structure, data, options) {
        this.structure = structure;
        this.data = data;

        this.options = {
            multiple: false,
            onSave: function() {},
            onCancel: function() {}
        };

        this.options = $.extend({}, {}, options);
        this.$scope = null;

        this.selected = [];

        this.ready = false;
    }

    CheckboxTree.prototype = {

        build: function() {
            var self = this;
            this.$scope = $('.option-tree.template').clone().removeClass('template');

            this.$scope.hide().appendTo(document.body);

            this.$scope.on('click', '[data-action]', function(event) {
                event.preventDefault();
                var action = camelCase(this.getAttribute('data-action'));

                if (action in self) {
                    self[action]();
                }
            });

            this.$tree = $(getHtmlList(this.structure, this.data));

            this.$content = this.$scope.find('.option-tree__content');
            this.$content.append(this.$tree);

            this.$tree
                .on('click', '.option-tree__toggle', function(event) {
                    self.toggle($(this).closest('li'));
                })
                .on('click', '.option-tree__checkbox', function(event) {
                    var node = $(this).closest('li');
                    if (!self.options.multiple) {
                        self.$tree.find('.is-checked').not(node).removeClass('is-checked');
                    }

                    $(this).closest('li').toggleClass('is-checked');
                });

            this.ready = true;
        },

        show: function(container) {

            if (!this.ready) {
                this.build();
                this.collapseAll();
            }

            this.$selected = this.$tree.find('.is-checked');

            var position = $(container).offset();
            var windowSize = {
                width: $(window).width(),
                height: $(window).height()
            }

            var scopeSize = {
                width: this.$scope.width(),
                height: this.$scope.height()
            }

            var scrollTop = $(window).scrollTop();

            if (windowSize.width < position.left + scopeSize.width) {
                position.left -= position.left + scopeSize.width - windowSize.width + 20;
            } else {
                position.left -= 40;
            }

            if (windowSize.height < position.top + scopeSize.height - scrollTop) {
                position.top -= position.top + scopeSize.height - scrollTop - windowSize.height + 20;
            } else {
                position.top -= 20;
            }

            this.$scope.css(position);

            this.$scope.show();

        },

        hide: function() {

            if (this.$scope) {
                this.$scope.hide();
            }

        },

        cancel: function() {
            this.hide();

            this.$tree.find('.is-checked').removeClass('is-checked');
            if (this.$selected) {
                this.$selected.addClass('is-checked');
            }

            if (typeof this.options.onCancel === 'function') {
                this.options.onCancel();
            }

            return this;
        },

        save: function() {
            this.hide();

            var selectedItems = this.getCheckedItems();

            if (typeof this.options.onSave === 'function') {
                this.options.onSave(selectedItems);
            }

            return this;
        },

        toggle: function(node) {
            var $node = $(node);

            if ($node.hasClass('is-collapsible')) {
                if ($node.hasClass('is-collapsed')) {
                    this.expand($node);
                } else {
                    this.collapse($node);
                }
            }

            return node;
        },

        expand: function(node) {
            var $node = $(node);

            if ($node.hasClass('is-collapsible')) {
                $node
                    .removeClass('is-collapsed')
                    .removeClass('is-partial')
                    .addClass('is-expanded');
            }

            return node;
        },

        expandAll: function() {
            var self = this;

            this.$tree.find('.is-collapsible').each(function() {
                self.expand(this);
            });

            return this;
        },

        collapse: function(node) {
            var $node = $(node);

            if ($node.hasClass('is-collapsible')) {
                $node
                    .removeClass('is-expanded')
                    .addClass('is-collapsed');

                if ($node.find('li.is-checked').length) {
                    $node.addClass('is-partial');
                }
            }

            return node;
        },

        collapseAll: function() {
            var self = this;

            this.$tree.find('.is-collapsible').each(function() {
                self.collapse(this);
            });

            return this;
        },

        expandChecked: function() {
            var self = this;
            var $nodes = this.$tree.find('.is-checked');

            $nodes.each(function() {
                $(this).parentsUntil('.option-tree', 'li').each(function() {
                    self.expand(this);
                });
            });

            return $nodes;
        },

        getCheckedItems: function() {
            return this.$tree.find('.is-checked').map(function() {
                return this.getAttribute('data-id');
            }).get();
        }
    }

    function TreeSelector(element, options) {
        this.scope = element;
        this.$scope = $(element);

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    TreeSelector.prototype = {

        init: function() {
            var self = this;

            this.$container = $(this.options.template);
            this.$list = this.$container.find('.tree-selector__list');

            this.$editButton = this.$container.find('.tree-selector__edit');

            // read options from element
            this.options.multiple = !!this.scope.multiple;
            this.options.name = this.scope.name;
            this.options.placeholder = null;
            this.options.separatorRegExp = escapeRegExp(this.options.separator);

            // calculate data
            this.$items = this.$scope.find('option').filter(function(item) {
                if (this.value.length === 0 && !self.options.placeholder) {
                    self.options.placeholder = this.textContent;
                }
                return this.value;
            });

            this.nodes = [];
            var parents = [];
            var regex = new RegExp(this.options.separatorRegExp, 'g');

            this.items = {};

            this.$items.toArray().forEach(function(element) {
                // calculate level
                var text = element.innerHTML.trim();
                var level = (text.match(regex) || []).length;
                var id = element.value;
                var label = text.split(self.options.separator).pop().trim();


                // create tree structure
                var node = {
                    id: id,
                    nodes: []
                }

                parents[level] = node;

                if (level > 0) {
                    parents[level - 1].nodes.push(node);
                } else {
                    this.nodes.push(node);
                }

                // create item cache
                this.items[id] = {
                    id: id,
                    text: label,
                    level: level,
                    selected: element.selected
                };

                if (level > 0) {
                    this.items[id].parentId = parents[level - 1].id;
                } else {
                    this.items[id].parentId = null;
                }

            }, this);

            parents = null;

            this.selector = new CheckboxTree(this.nodes, this.items, {
                multiple: this.options.multiple,
                onCancel: this.cancel.bind(this),
                onSave: this.save.bind(this)
            });

            this.attach();

            return this;
        },

        render: function() {
            var selected = this.getSelected();

            this.$list.empty();

            selected.forEach(function(id) {
                var item = substitute(this.options.selectedItemTemplate, {
                    path: getPath(id, this.items),
                    id: id,
                    name: this.options.name
                });
                this.$list.append(item);
            }, this);

            if (this.$list.is(':empty') && this.options.placeholder) {
                this.$list.append(substitute(this.options.emptyTemplate, {
                    placeholder: this.options.placeholder,
                    name: this.options.name
                }));
            }

            this.$list.trigger('dom:change');

            return this;
        },

        open: function() {
            this.selector.show(this.$container);
        },

        close: function() {
            this.selector.hide();
        },

        attach: function() {
            this.render();

            this.$scope.after(this.$container);
            this.$scope.detach();

            this.$editButton.on('click.treeselector', this.open.bind(this));


            return this;
        },

        detach: function() {
            this.$container.after(this.$scope);
            this.$container.remove();
            this.$list = null;
            this.$editButton.off('.treeselector');

            return this;
        },

        getSelected: function() {
            var self = this;
            return Object.keys(this.items).filter(function(key) {
                return self.items[key].selected;
            });
        },

        setSelected: function(selectedItems) {
            var self = this;
            Object.keys(this.items).forEach(function(key) {
                self.items[key].selected = false;
            });

            selectedItems.forEach(function(key) {
                self.items[key].selected = true;
            });

            return this;
        },

        cancel: function() {

        },

        save: function(selectedItems) {
            this.setSelected(selectedItems);
            this.render();

            return this;
        }

    };

    $.fn[pluginName] = function (options) {
        var dataPrefixLength = dataPrefix.length;

        return this.each(function () {
            if (!$.data(this, 'plugin-' + pluginName)) {

                // parse data-options
                var data = $(this).data(),
                    options = {},
                    settings = '';

                if (pluginName in data) {
                    settings = data[pluginName];
                    // pass options
                    if ($.type(settings) === 'object') {
                        options = settings;
                    }
                }

                // pass all options to plugin
                $.each(data, function(key, value) {
                    if (key.substr(0, dataPrefixLength) === dataPrefix && key !== dataPrefix) {
                        var id = key.substr(dataPrefixLength);
                        id = id.substr(0,1).toLowerCase() + id.substr(1);
                        options[id] = value;
                    }
                });

                $.data(this, 'plugin-' + pluginName, new TreeSelector(this, options));
            }
        });
    };

    $.fn[pluginName].defaults = defaults;

    // listen to change event and init self
    $(document).on('dom:change', function(event) {
        $('[data-tree-selector]', event.target).giantTreeSelector();
    });
    $('[data-tree-selector]').giantTreeSelector();

    Giant.TreeSelector = TreeSelector;

})(jQuery, window, document);