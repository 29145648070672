/**
 * Initialization of sortable to provide automatic data-binding and data-configuration.
 */
var Giant = Giant || {};

;(function($, window, document, undefined) {

    var pluginName = 'giantBatchOperation',
        dataPrefix = 'batch-operation',
        $window = $(window),
        defaults = {
        };

    function BatchOperation(element, options) {
        this.scope = element;
        this.$scope = $(element);

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    BatchOperation.prototype = {

        init: function() {
            var self = this;

            this.$master = this.$scope.find('thead input[type=checkbox][data-master]');
            this.$checkboxes = this.$scope.find('tbody input[type=checkbox]');
            this.$actions = this.$scope.find('.batch-operation select, .batch-operation button');

            this.$table = this.$scope.find('table');

            this.counter = 0;

            this.attach();

            this.refresh();

            return this;
        },

        selectAll: function() {
            var self = this;
            this.$checkboxes.each(function() {
                if (!this.checked) {
                    self.counter++;
                    this.checked = true;
                }
            });
        },

        deselectAll: function() {
            var self = this;
            this.$checkboxes.each(function() {
                if (this.checked) {
                    self.counter--;
                    this.checked = false;
                }
            });
        },

        refresh: function() {
            this.$actions.attr('disabled', !this.counter);
            if (this.$checkboxes.length) {
                this.$master.attr('checked', !!(this.counter === this.$checkboxes.length));
            }
        },

        attach: function() {
            var self = this;

            this.$table.on('click.batch-operation', 'input[type=checkbox]', function(event) {
                var $checkbox = $(this);
                var checkbox = this;

                if (typeof $checkbox.data('master') === 'undefined') {
                    checkbox.checked ? self.counter++ : self.counter--;
                } else {
                    if (checkbox.checked) {
                        self.selectAll();
                    } else {
                        self.deselectAll();
                    }
                }

                self.refresh();
            });

            return this;
        },

        detach: function() {
            this.$table.off('.batch-operation');

            return this;
        }

    };

    $.fn[pluginName] = function (options) {
        var dataPrefixLength = dataPrefix.length;

        return this.each(function () {
            if (!$.data(this, 'plugin-' + pluginName)) {

                // parse data-options
                var data = $(this).data(),
                    options = {},
                    settings = '';

                if (pluginName in data) {
                    settings = data[pluginName];
                    // pass options
                    if ($.type(settings) === 'object') {
                        options = settings;
                    }
                }

                // pass all options to plugin
                $.each(data, function(key, value) {
                    if (key.substr(0, dataPrefixLength) === dataPrefix && key !== dataPrefix) {
                        var id = key.substr(dataPrefixLength);
                        id = id.substr(0,1).toLowerCase() + id.substr(1);
                        options[id] = value;
                    }
                });

                $.data(this, 'plugin-' + pluginName, new BatchOperation(this, options));
            }
        });
    };

    $.fn[pluginName].defaults = defaults;

    // listen to change event and init self
    $(document).on('dom:change', function(event) {
        $('.grid', event.target).giantBatchOperation();
    });
    $('.grid').giantBatchOperation();

    Giant.BatchOperation = BatchOperation;

})(jQuery, window, document);