(function() {

    $(document).on('click', '[data-trigger]', function(event) {
        var $target = $(this);

        var action = $target.data('trigger');
        var value = $target.data('trigger-value');

        if (action) {
            event.preventDefault();
            $target.trigger(action, value);
        }
    });

})();