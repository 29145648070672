var Giant = Giant || {};

;(function($, window, document, undefined) {

    var pluginName = 'giantTree',
        dataPrefix = 'tree',
        $window = $(window),
        defaults = {
            sortableUrl: null,
            openClass: 'tree__node--open',
            progressClass: 'tree__node--progress',
            nodeSelector: '.tree__node',
            childSelector: '> .tree__children',
            bodySelector: '.tree__body',

            // sortable options
            handle: '.handle',
            placeholder: 'tree__sortable-placeholder',
            items: '.tree__node',
            connectWith: '.tree__children',
            toleranceElement: '> .tree__row'
        };

    function Tree(element, options) {
        this.scope = element;
        this.$scope = $(element);

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    Tree.prototype = {

        init: function() {
            var self = this;

            this.attach();

            return this;
        },

        load: function(toggle) {
            var self = this;
            var $node = $(toggle).closest(this.options.nodeSelector);

            $.ajax({
                url: toggle.href,
                dataType: 'JSON',
                method: 'GET',
                beforeSend: function() {
                    $node.addClass(self.options.progressClass);
                }
            }).done(function(response) {
                $node.removeClass(self.options.progressClass);

                if (!('snippets' in response)) {
                    return;
                }

                var $buffer = $('<ul class="tree__children"></ul>');
                var keys = Object.keys(response.snippets);

                keys.forEach(function(key) {
                    var $row = $('<li class="tree__node"></li>').html('<div class="tree__row">' + response.snippets[key] + '</div>');
                    var id = $row.find('.col-toggle').data('id');
                    $row.attr('data-id', id);
                    $row.find('.tree__row').attr('id', key);
                    $buffer.append($row);
                });

                $node.append($buffer);
                $node.trigger('add.tree');
                $node.find(self.options.childSelector).trigger('dom:change');

                setTimeout(function() {
                    self.open($node);
                }, 100);
            });
        },

        open: function($node) {
            $node.addClass(this.options.openClass);
            $node.find(this.options.childSelector).slideDown(200);
            $node.trigger('change.node.tree', true);
            this.$scope.trigger('change.tree');

            this.$scope.sortable('refresh');
            return this;
        },

        close: function($node) {
            $node.removeClass(this.options.openClass);
            $node.find(this.options.childSelector).slideUp(200);
            $node.trigger('change.node.tree', false);
            this.$scope.trigger('change.tree');
            return this;
        },

        save: function(id, previous, parent) {
            $.ajax({
                url: this.options.sortableUrl,
                dataType: 'json',
                method: 'GET',
                data: {
                    id: id,
                    previous: previous,
                    parent: parent
                }
            });
            return this;
        },

        sort: function($node) {
            var current = $node.data('id');
            var previous = $node.prev().data('id');
            var parent = $node.parent().closest(this.options.nodeSelector).data('id');

            if (typeof previous === 'undefined') {
                previous = null;
            }

            if (typeof parent === 'undefined') {
                parent = null;
            }

            this.save(current, previous, parent);
        },

        attach: function() {
            var self = this;
            this.$scope.on('click.tree', '.btn-toggle', function(event) {
                event.preventDefault();
                var $node = $(this).closest(self.options.nodeSelector);
                var $children = $node.find(self.options.childSelector);

                if ($node.hasClass(self.options.openClass)) {
                    self.close($node);
                    return;
                }

                if ($children.length) {
                    self.open($node);
                    return;
                }

                self.load(this);
            });

            if (this.options.sortableUrl !== null) {
                this.$scope.sortable({
                    handle: self.options.handle,
                    placeholder: self.options.placeholder,
                    items: self.options.items,
                    connectWith: self.options.connectWith,
                    toleranceElement: self.options.toleranceElement,
                    forcePlaceholderSize: true,
                    update: function(event, ui) {
                        self.sort(ui.item);
                    }
                });
            }
        },

        detach: function() {
            this.$scope.off('.tree');
        }

    };

    $.fn[pluginName] = function (options) {
        var dataPrefixLength = dataPrefix.length;

        return this.each(function () {
            if (!$.data(this, 'plugin-' + pluginName)) {

                // parse data-options
                var data = $(this).data(),
                    options = {},
                    settings = '';

                if (pluginName in data) {
                    settings = data[pluginName];
                    // pass options
                    if ($.type(settings) === 'object') {
                        options = settings;
                    }
                }

                // pass all options to plugin
                $.each(data, function(key, value) {
                    if (key.substr(0, dataPrefixLength) === dataPrefix && key !== dataPrefix) {
                        var id = key.substr(dataPrefixLength);
                        id = id.substr(0,1).toLowerCase() + id.substr(1);
                        options[id] = value;
                    }
                });

                $.data(this, 'plugin-' + pluginName, new Tree(this, options));
            }
        });
    };

    $.fn[pluginName].defaults = defaults;

    // listen to change event and init self
    $(document).on('dom:change', function(event) {
        $('[data-tree]', event.target).giantTree();
    });
    $('[data-tree]').giantTree();

    Giant.Tree = Tree;

})(jQuery, window, document);