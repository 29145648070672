(function() {

    var selected = null;
    var timer = Date.now();

    function move(origin, previous) {
        var $row = $(origin).closest('tr');
        var index = $row.find('td').index(origin);

        if (index === -1) {
            return;
        }

        var $next = $row[previous ? 'prev' : 'next']().find('td').eq(index);


        if ($next.length) {
            deselect(origin);
            select($next);
        }
    }

    function previous(origin) {

    }

    function select(cell) {
        var $cell = $(cell);
        $cell.data('value', $cell.text().trim());
        $cell.on('click.edit', function(event) {
            event.stopPropagation();
        });

        var height = $cell.height();
        height += parseInt($cell.css('padding-top'));
        height += parseInt($cell.css('padding-bottom'));

        var $textarea = $('<textarea></textarea>');
        $textarea.css('height', height).val($cell.data('value'));
        $textarea.on('keydown', function(event) {
            if (event.keyCode === 9) {
                event.preventDefault();
                move(cell, event.shiftKey);
            }
        });
        $cell.addClass('edit--active');
        $cell.empty().append($textarea);

        $textarea.focus();

        selected = cell;
    }

    function deselect(cell) {
        var $cell = $(cell);

        var value = $cell.find('textarea').val().trim();
        var defaultValue = $cell.data('value');

        $cell.off('.edit');

        $cell.text(value).removeClass('edit--active');

        if (value !== defaultValue) {
            $.nette.ajax({
                url: $cell.data('edit-url'),
                data: {
                    value: value
                },
                method: 'POST'
            });
        }

        selected = null;
    }

    $(document).on('click', '[data-edit-url]', function(event) {
        if (selected) {
            deselect(selected);
        }

        if (selected !== event.target) {
            select(event.target);
        }
    });

    $(document).on('click', function(event) {
        if (selected && selected !== event.target) {
            deselect(selected);
        }
    });
})();