const requests = {}

export function fetchById(
    id,
    options = {
        abort: false
    }
) {
    if (requests[id]) {
        if (options.abort) {
            requests[id].controller.abort()
        } else if (!requests[id].promise.done) {
            return requests[id].promise
        }
    }

    const controller = new AbortController()
    const signal = controller.signal

    const promise = new Promise((resolve, reject) => {
        fetch(`${FILEMANAGER_API}/${id}`, {
            signal
        })
        .then(response => response.json())
        .then(response => response.data)
        .then(data => resolve(data))
        .catch(error => {
            console.error(error)
            reject(error)
        })
    })
    promise.done = false

    promise.finally(() => promise.done = true)

    requests[id] = {
        controller,
        promise
    }

    return promise
}
