(function() {

    var $elements = $('input[type=checkbox][data-toggle]');

    if ($elements.length === 0) {
        return;
    }

    $elements.on('change', function(event) {
        var $this = $(this);
        var $target = $($this.data('toggle'));

        if (this.checked) {
            $target.collapse('show');
        } else {
            $target.collapse('hide');
        }
    });

})();