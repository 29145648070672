(function() {

    function position(element, button) {
        var $element = $(element);
        var $button = $(button);
        var elementOffset = $element.show().offset().left;
        $element.hide();
        var buttonOffset = $button.offset().left;

        var $arrow = $element.find('.help__arrow');

        $arrow.css('left', buttonOffset - elementOffset + $button.outerWidth()/2 - $arrow.outerWidth()/2);
    }

    function show(element, button) {
        if (element) {
            position(element, button);
            $(element).slideDown(200);
        }
    }

    function hide(element, button) {
        if (element) {
            $(element).slideUp(200);
        }
    }

    function toggle(element, button) {
        if (!element) {
            return;
        }

        if ($(element).is(':visible')) {
            hide(element, button);
        } else {
            show(element, button);
        }
    }

    function getElement(button) {
        var $button = $(button);

        var $parent = $button.parent();

        while (!$parent.find('.help').length && $parent[0] !== document.body) {
            $parent = $parent.parent();
        }

        var $element = $parent.find('.help');

        if ($element.length) {
            return $element[0];
        } else {
            return null;
        }
    }

    $(document).on({
        'show.help': function(event) {
            show(getElement(event.target), event.target);
        },

        'hide.help': function(event) {
            hide(getElement(event.target), event.target);
        },

        'toggle.help': function(event) {
            toggle(getElement(event.target), event.target);
        }
    });

})();
