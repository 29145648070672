import $ from 'jquery';

var pluginName = 'giantImageArea',
    dataPrefix = 'imageArea',
    $window = $(window),
    defaults = {
        width: 0,
        height: 0,
        scale: 1,
        x: 'auto',
        y: 'auto'
    };

function ImageArea(element, options) {
    this.scope = element;
    this.$scope = $(element);

    this.options = $.extend({}, defaults, options);

    this._defaults = defaults;
    this._name = pluginName;

    this.init();
}

ImageArea.prototype = {

    init: function() {
        var self = this;

        this.$container = this.$scope.find('.thumbnail');
        this.container = {
            width: 0,
            height: 0
        }

        this.image = {
            left: 0,
            top: 0,
            scale: 1,
            width: 0,
            height: 0,
            minScale: 1
        }

        this.originImage = $.extend({}, this.image);

        this.coords = {
            x: 0,
            y: 0
        }

        this.output = {
            '$x': this.$scope.find('[data-image-area-x]'),
            '$y': this.$scope.find('[data-image-area-y]'),
            '$width': this.$scope.find('[data-image-area-width]'),
            '$height': this.$scope.find('[data-image-area-height]')
        }

        this.dragging = false;
        this.pointerDown = false;

        this.resize();

        this.refresh();

        if (this.$image.length) {
            this.load();
        }

        this.boundOnChange = this.onChange.bind(this);

        this.attach();

        return this;
    },

    attach: function() {
        this.$scope.on({
            'change.bs.fileinput': this.boundOnChange,
            'mousedown.imageArea': function(event) {
                event.preventDefault();
                if (event.target.tagName.toLowerCase() === 'img') {
                    this.coords = {
                        x: event.pageX,
                        y: event.pageY
                    }
                    this.pointerDown = true;
                }
            }.bind(this),
            'mousemove.imageArea': function(event) {
                event.preventDefault();
                if (!this.pointerDown) {
                    return;
                }
                if (!this.dragging && (Math.abs(this.coords.x - event.pageX) > 5 || Math.abs(this.coords.y - event.pageY) > 5)) {
                    this.dragging = true;
                }

                if (this.dragging) {
                    var diff = {
                        x: this.coords.x - event.pageX,
                        y: this.coords.y - event.pageY
                    }

                    this.coords.x = event.pageX;
                    this.coords.y = event.pageY;

                    this.drag(diff.x, diff.y);
                }
            }.bind(this),
            'mouseup.imageArea': function(event) {
                event.preventDefault();
                this.pointerDown = false;
                this.dragging = false;
            }.bind(this),
            'wheel.imageArea': function(event) {
                event.preventDefault();
                var diff = Math.abs(this.image.scale/10);
                this.scale(event.originalEvent.deltaY > 0 ? -diff : diff);
            }.bind(this)
        });
        return this;
    },

    detach: function() {
        this.$scope.off('change.bs.fileinput', this.boundOnChange);
        return this;
    },

    drag: function(x, y) {
        this.image.top = Math.min(Math.max(this.container.height - this.image.height, this.image.top - y), 0);
        this.image.left = Math.min(Math.max(this.container.width - this.image.width, this.image.left - x), 0);
        this.render();
    },

    scale: function(diff) {
        var scale = this.image.scale + diff;

        if (scale > this.container.scale) {
            scale = this.container.scale;
        }

        if (scale < this.image.minScale) {
            scale = this.image.minScale;
        }

        var diff = scale - this.image.scale;

        this.image.top *= 1 + diff;
        this.image.left *= 1 + diff;
        this.image.width = this.rawImage.width * scale;
        this.image.height = this.rawImage.height * scale;
        this.image.scale = scale;

        // check positions
        if (this.image.top + this.image.height < this.container.height) {
            this.image.top = this.container.height - this.image.height;
        }

        if (this.image.left + this.image.width < this.container.width) {
            this.image.left = this.container.width - this.image.width;
        }

        this.render();
    },

    resize: function() {

        this.container = {
            width: this.$container.width(),
            height: this.$container.height()
        }

        this.container.scale = this.container.width/this.options.width;

        if (this.rawImage) {
            var containerRatio = this.container.width/this.container.height;
            var imageRatio = this.rawImage.width/this.rawImage.height;

            if (containerRatio > imageRatio) {
                this.image.minScale = this.container.width/this.rawImage.width;
            } else {
                this.image.minScale = this.container.height/this.rawImage.height;
            }
            this.render();
        }
    },

    onChange: function() {
        this.refresh();
        this.fit();
    },

    refresh: function() {
        this.$image = this.$scope.find('img');
        this.$image.attr('draggable', false);
        this.rawImage = new Image();
        this.rawImage.src = this.$image[0].src;
    },

    fit: function() {
        // scale and center image
        var containerRatio = this.container.width/this.container.height;
        var imageRatio = this.rawImage.width/this.rawImage.height;

        if (containerRatio > imageRatio) {
            // scale by width
            this.image.scale = this.image.minScale = this.container.width/this.rawImage.width;

            this.image.width = this.container.width;
            this.image.height = this.image.scale * this.rawImage.height;
            this.image.left = 0;
            this.image.top = -Math.round((this.image.height - this.container.height)/2);

        } else {
            // scale by height
            this.image.scale = this.image.minScale = this.container.height/this.rawImage.height;

            this.image.height = this.container.height;
            this.image.width = this.image.scale * this.rawImage.width;
            this.image.left = -Math.round((this.image.width - this.container.width)/2);
            this.image.top = 0;
        }

        this.render();
    },

    render: function() {
        this.$image.css({
            width: this.image.width,
            height: this.image.height,
            left: this.image.left,
            top: this.image.top
        });

        this.save();
    },

    save: function() {
        // calculate bounding box scaled to target area
        var box = {
            x: -Math.round(this.image.left/this.image.scale),
            y: -Math.round(this.image.top/this.image.scale),
            width: Math.round(this.container.width/this.image.scale),
            height: Math.round(this.container.height/this.image.scale)
        }

        this.output.$x.val(box.x);
        this.output.$y.val(box.y);
        this.output.$width.val(box.width);
        this.output.$height.val(box.height);
    },

    load: function() {
        var x = parseInt(this.output.$x.val()) || 0;
        var y = parseInt(this.output.$y.val()) || 0;
        var width = parseInt(this.output.$width.val()) || 0;
        var height = parseInt(this.output.$height.val()) || 0;

        var rawImage = new Image();
        rawImage.onload = function() {
            this.rawImage = rawImage;
            this.image.minScale = this.container.width/this.rawImage.width;
            this.image.scale = width === 0 ? 1 : this.container.width/width;

            this.image.left = -x * this.image.scale;
            this.image.top = -y * this.image.scale;
            this.image.width = this.rawImage.width * this.image.scale;
            this.image.height = this.rawImage.height * this.image.scale;

            if (x === 0 && y === 0 && height === 0 && width === 0) {
                this.fit();
            }

            this.render();
        }.bind(this);
        rawImage.src = this.$image.attr('src');
    }

};

$.fn[pluginName] = function (options) {
    var dataPrefixLength = dataPrefix.length;

    return this.each(function () {
        if (!$.data(this, 'plugin-' + pluginName)) {

            // parse data-options
            var data = $(this).data(),
                options = {},
                settings = '';

            if (dataPrefix in data) {
                settings = data[dataPrefix];
                // pass options
                if ($.type(settings) === 'object') {
                    options = settings;
                }
            }

            // pass all options to plugin
            $.each(data, function(key, value) {
                if (key.substr(0, dataPrefixLength) === dataPrefix && key !== dataPrefix) {
                    var id = key.substr(dataPrefixLength);
                    id = id.substr(0,1).toLowerCase() + id.substr(1);
                    options[id] = value;
                }
            });

            $.data(this, 'plugin-' + pluginName, new ImageArea(this, options));
        }
    });
};

$.fn[pluginName].defaults = defaults;

// listen to change event and init self
$(document).on('dom:change', function(event) {
    $('[data-image-area]', event.target).filter(function() {
        return $(this).closest('.template').length === 0;
    }).giantImageArea();
});
$('[data-image-area]').filter(function() {
    return $(this).closest('.template').length === 0;
}).giantImageArea();

export default ImageArea;
