(function() {
    var $nav = $('.navbar-main .nav-root');

    $nav
        .on('show.bs.collapse', function(event) {
            $nav.find('> .active').removeClass('active');
            $(event.target).closest('li').addClass('active');
            $nav.find('.collapse.in').collapse('hide');
        })
        .on('hide.bs.collapse', function(event) {
            $(event.target).closest('li').removeClass('active');
        });
})();