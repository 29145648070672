import FmElement from './fm-element'
import { openModal } from './components/FilemanagerModal'
import { fetchById } from './utils/api'

const defaults = {
    accept: '',
    folder: null,
    name: '',
    value: '',
    multiple: false
}

const attributes = ['accept', 'folder', 'name', 'value', 'multiple', 'readonly', 'netterules']

class FmFile extends FmElement {
    constructor() {
        super({
            templateId: 'fm-file',
            attributes
        }, defaults)
    }

    get value() {
        return this.ref?.input.value
    }

    set value(value) {
        if (this.ref?.input) {
            this.ref.input.value = value
        }
    }

    get data() {
        return this.state.item
    }

    prepare() {
        this.ref = {
            root: null,
            caption: null,
            removeButton: null,
            selectButton: null
        }

        this.ref.input = document.createElement('input')
        this.ref.input.type = 'hidden'
        this.ref.input.name = this.options.name
        this.ref.input.value = this.options.value
        this.ref.input.dataset.netteRules = this.options.netterules
        this.appendChild(this.ref.input)

        const { accept } = this.options

        this.modalOptions = {
            accept,
            select: this.options.multiple ? 'multiple' : 'single',
            onSelect: this.handleSelect
        }

        if (this.options.folder) {
            this.modalOptions.folder = `${this.options.folder}`
        }

        this.state = {
            item: null,
        }
        
        this.state.value = this.ref.input.value

        this.ref.selectButton.addEventListener('click', this.handleSelectClick)
        this.ref.removeButton.addEventListener('click', this.handleRemoveClick)

        if (this.state.value) {
            const value = this.state.value
            fetchById(value)
                .then(data => {
                    if (!this.isConnected) {
                        return
                    }

                    if (value === this.state.value) {
                        this.state.item = {...data}
                        this.render()
                    }
                })
        } else {
            this.render()
        }
    }

    handleSelectClick = event => {
        event.preventDefault()
        openModal(this.modalOptions)
    }

    handleRemoveClick = event => {
        event.preventDefault()
        this.reset()
    }

    handleSelect = response => {
        const { data: [item] } = response

        this.state.item = item
        this.render()

        this.ref.input.value = item.id

        this.dispatchEvent(new Event('change', {
            bubbles: true
        }))
    }

    reset() {
        this.state.item = null
        this.render()

        this.dispatchEvent(new Event('change', {
            bubbles: true
        }))
    }

    render() {
        const { item } = this.state

        if (item) {
            this.ref.caption.textContent = [item.name, item.extension].filter(Boolean).join('.')
            this.ref.caption.href = item.link
            this.ref.input.value = item.id
        } else {
            this.ref.caption.href = '#'
            this.ref.caption.textContent = ''
            this.ref.input.value = ''
        }

        this.ref.root.classList.toggle('is-empty', !item)
        if (item) {
            this.ref.removeButton.removeAttribute('tabindex')
            this.ref.caption.removeAttribute('tabindex')
        } else {
            this.ref.removeButton.setAttribute('tabindex', -1)
            this.ref.caption.setAttribute('tabindex', -1)
        }

        this.ref.removeButton.disabled = this.hasAttribute('readonly')
        this.ref.selectButton.disabled = this.hasAttribute('readonly')

        this.ref.root.classList.toggle('is-readonly', this.hasAttribute('readonly'))

    }
}

customElements.define('fm-file', FmFile)
