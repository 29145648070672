import FmElement from './fm-element'
import { openModal } from './components/FilemanagerModal'

const defaults = {
    accept: '',
    folder: null,
    multiple: false
}

const attributes = ['accept', 'folder', 'multiple']

class FmButton extends FmElement {
    constructor() {
        super({
            attributes
        }, defaults)
    }

    prepare() {
        this.setAttribute('tabindex', 0)

        const { accept } = this.options

        this.modalOptions = {
            accept,
            select: this.options.multiple ? 'multiple' : 'single',
            onSelect: this.handleSelect
        }

        if (this.options.folder) {
            this.modalOptions.folder = `${this.options.folder}`
        }
        
        this.addEventListener('click', this.handleClick)
    }

    handleClick = event => {
        event.preventDefault()
        openModal(this.modalOptions)
    }

    handleSelect = response => {
        const { data } = response

        const detail = this.options.multiple ? data : data[0]

        this.dispatchEvent(new CustomEvent('fm:select', {
            bubbles: true,
            detail
        }))
    }
}

customElements.define('fm-button', FmButton)
