import FmElement from "./fm-element"
import { openModal } from "./components/FilemanagerModal"
import { fetchById } from "./utils/api"

const defaults = {
    accept: "image/*",
    fit: "shrink_only",
    width: 0,
    height: 0,
    folder: null,
    name: "",
    value: "",
    multiple: false,
}

const attributes = ["width", "height", "accept", "fit", "folder", "name", "value", "multiple", "readonly", "thumbwidth", "thumbheight"]

class FmImage extends FmElement {
    constructor() {
        super(
            {
                templateId: "fm-image",
                attributes,
            },
            defaults
        )

        this.state = {
            item: null,
            width: 0,
            height: 0,
            intersected: false
        }

        this._value = ''
        this.value = this.options.value
    }

    get value() {
        return this._value
    }

    set value(value) {
        if (value === this._value) {
            return
        }

        this._value = value

        if (this.ref.input) {
            this.ref.input.value = value
        }

        if (value) {
            if (this.state.intersected) {
                this.fetchAndRender()
            }
        } else {
            this.reset()
        }

        this.dispatchEvent(
            new Event("change", {
                bubbles: true,
            })
        )
    }

    get data() {
        return this.state.item
    }

    prepare() {
        this.ref = {
            input: null,
            thumbnail: null,
            root: null,
            removeButton: null,
        }

        this.ref.input = document.createElement("input")
        this.ref.input.type = "hidden"
        this.ref.input.name = this.options.name
        this.ref.input.value = this.value

        const { accept } = this.options

        this.modalOptions = {
            accept,
            select: this.options.multiple ? "multiple" : "single",
            onSelect: this.handleSelect,
        }

        if (this.options.folder) {
            this.modalOptions.folder = `${this.options.folder}`
        }

        this.appendChild(this.ref.input)

        this.ref.thumbnail.addEventListener("click", this.handleClick)
        this.ref.removeButton.addEventListener("click", this.handleRemoveClick)

        this.observer = new IntersectionObserver(this.handleIntersection)
        this.observer.observe(this)

        this.render()
    }

    handleIntersection = entries => {
        if (!entries[0].isIntersecting || this.state.loaded) {
            return
        }
        this.observer.disconnect()
        this.observer = null
        this.state.intersected = true
        this.fetchAndRender()
    }

    handleClick = (event) => {
        event.preventDefault()
        openModal(this.modalOptions)
    }

    handleRemoveClick = (event) => {
        event.preventDefault()

        this.reset()
    }

    handleSelect = (response) => {
        const {
            data: [item],
        } = response

        this.state.item = item
        this.render()

        this.value = item.id
    }

    handleImageError = () => {
        this.ref.root.classList.add("has-invalidImage")
        this.ref.root.classList.remove("is-empty")
    }

    handleImageLoad = () => {
        this.ref.root.classList.remove("has-invalidImage")
        this.ref.root.classList.remove("is-empty")
    }

    fetchAndRender() {
        const value = this.value
        fetchById(value)
            .then((data) => {
                if (value === this.value) {
                    this.state.item = { ...data }
                }
                if (this.isConnected) {
                    this.render()
                }
            })
            .catch((error) => { })
    }

    reset() {
        this.ref.input.value = ""
        this.ref.root.classList.remove("has-invalidImage")
        this.ref.root.classList.add("is-empty")
        this.ref.thumbnail.innerHTML = ""
        this.state.item = null
    }

    refresh() {
        this.resize()
        this.render()
    }

    resize() {
        const box = this.ref.thumbnail.getBoundingClientRect()
        this.state.width = this.options.width || Math.floor(box.width)
        this.state.height = this.options.height || Math.floor(box.height)
    }

    render() {
        if (this.state.width === 0 || this.state.height == 0) {
            this.resize()
        }
        if (this.state.width === 0 || this.state.height == 0) {
            return
        }

        const { fit, thumbwidth, thumbheight } = this.options
        const { width, height, item } = this.state

        if (item) {
            if (!item.link) {
                this.handleImageError()
                return
            }
            //override auto-thumbnail size podle parenta
            console.log(thumbwidth)
            const thumbWidth = thumbwidth ?? width
            const thumbHeight = thumbheight ?? height

            const extension = item.link.split('.').at(-1)
            const src = item.link.replace(
                /\.[a-z]+$/,
                `.${thumbWidth}x${thumbHeight}.${fit}.q85.${extension}`,
            )
            const retina = item.link.replace(
                /\.[a-z]+$/,
                `.${thumbWidth * 2}x${thumbHeight * 2}.${fit}.q85.${extension}`,
            )

            const image = new Image()
            image.onerror = this.handleImageError
            image.onload = this.handleImageLoad
            image.setAttribute("srcset", `${src} 1x, ${retina} 2x`)

            this.ref.thumbnail.innerHTML = ""
            this.ref.thumbnail.appendChild(image)
            this.ref.input.value = item.id
        }

        this.ref.root.classList.toggle("is-empty", !item)
        this.ref.root.classList.toggle("is-readonly", this.hasAttribute("readonly"))
    }
}

customElements.define("fm-image", FmImage)


$(document).on('shown.bs.tab', event => {
    const tabId = event.target.href?.split('#')[1]
    const tab = document.getElementById(tabId)
    if (!tab) {
        return
    }

    const fmImages = [...tab.querySelectorAll('fm-image')]
    fmImages.forEach(fmImage => {
        fmImage.refresh()
    })
})
