// jen binding, vyzaduje chosen.js pres bower
(function() {

    var attachChosen = function(context) {
        $('select[multiple]:not([data-tree-selector])', context || document.body).chosen({
            placeholder_text_multiple: 'Vyberte',
            placeholder_text_single: 'Vyberte'
        });
    }

    attachChosen();

    $(document).on('dom:change', function(event) {
        attachChosen(event.target);
    });

})();