class FmElement extends HTMLElement {
    constructor({
        templateId = '',
        attributes = []
    }, defaults = {}) {
        super()
        this._ref = {}
        this.controller = null

        this.options = {}

        if (Array.isArray(attributes)) {
            const options = Object.fromEntries(
                attributes
                    .filter(attribute => this.hasAttribute(attribute))
                    .map(attribute => {
                        let value = this.getAttribute(attribute)

                        const number = parseInt(value, 10)

                        if (!isNaN(number)) {
                            value = number
                        }

                        if (attribute === 'multiple') {
                            value = true
                        }

                        return [attribute, value]
                    })
            )

            this.options = {
                ...defaults,
                ...options
            }
        }

        this.templateId = templateId
    }
    
    get ref() {
        return this._ref
    }

    set ref(items = {}) {
        const allRefs = Array.from(this.querySelectorAll('[data-ref]'))

        this._ref = Object.keys(items)
            .map(key => {
                const isArray = Array.isArray(items[key])

                // non-empty refs
                if (items[key] !== null && (isArray && items[key].length > 0)) {
                    return {
                        name: key,
                        value: items[key]
                    }
                }

                const name = isArray ? key.slice(0, -1) : key
                let refs = allRefs.filter(element => element.dataset.ref === name)

                if (!isArray) {
                    refs = refs.length ? refs[0] : null
                }

                return {
                    name: key,
                    value: refs
                }
            })
            .reduce((acc, ref) => {
                acc[ref.name] = ref.value
                return acc
            }, {})

        return this._ref
    }

    connectedCallback() {
        if (!this.isConnected) {
            return
        }

        if (this.templateId) {
            const template = document.getElementById(this.templateId).content
            this.appendChild(template.cloneNode(true))
        }

        this.prepare()
    }

    disconnectedCallback() {
        if (this.destroy) {
            this.destroy()
        }
    }
}

export default FmElement
