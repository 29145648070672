import $ from 'jquery';

const defaults = {
    defaults: null,
    name: '',
    prefix: '',
    parse: null,
    autoLoad: true
}

function camelCase(str) {
    return String(str).replace(/-\D/g, match => match.charAt(1).toUpperCase());
}

// config = {
//     defaults: obj,
//     name:  string,
//     prefix: string,
//     parse: function
//     autoLoad: boolean
// }

export default function createPlugin(Plugin, config) {
    config = Object.assign({}, defaults, config);
    const { name, prefix } = config;
    const prefixName = camelCase(prefix);

    $.fn[name] = function (options) {
        const prefixIndex = prefixName.length;

        return this.each(function () {
            if (!$.data(this, `plugin-${name}`)) {

                // parse data-options
                const data = $(this).data();
                let options = {};
                let settings = null;

                if (name in data) {
                    settings = data[name];
                    // pass options
                    if (typeof settings === 'object') {
                        options = settings;
                    }

                    // parse custom shortcuts
                    if (typeof settings === 'string' && typeof config.parse === 'function') {
                        options = config.parse(settings);
                    }
                }

                // pass all options to plugin
                $.each(data, (key, value) => {
                    if (key.substr(0, prefixIndex) === prefixName && key !== prefixName) {
                        let id = key.substr(prefixIndex);
                        id = id.substr(0,1).toLowerCase() + id.substr(1);
                        options[id] = value;
                    }
                });

                $.data(this, `plugin-${name}`, new Plugin(this, options));
            }
        });
    };

    $.fn[name].defaults = config.defaults;

    if (config.autoLoad) {
        $(document).on('dom:change', function(event) {
            $(`[data-${prefix}]`, event.target)[name]();
        });
        $(`[data-${prefix}]`)[name]();
    }
}