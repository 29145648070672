import $ from 'jquery'

const pluginName = 'cloner'
const dataPrefix = 'cloner'
const defaults = {
    item: 'tr', // item selector
    target: 'tbody', // element where new items will be placed
    position: 'append', // append | prepend | after | before
    template: '.template', // template selector
    min: 0,
    max: Infinity,
    indexType: 'integer', // required for autoindexing items, type time|incremental, time only for now
    indexName: 'index', // default index name used in template
    confirm: 'Opravdu chcete odebrat tuto položku?' // confirmation message before removing
}

function substitute(str, object, regexp) {
    return String(str).replace(regexp || (/\\?\{([^{}]+)\}/g), function (match, name) {
        if (match.charAt(0) == '\\') return match.slice(1)
        return (object[name] != null) ? object[name] : ''
    })
}

export default class Cloner {

    constructor(element, options) {
        this.scope = element
        this.$scope = $(element)

        this.options = $.extend({}, defaults, options)

        this.$emmiter = $({})

        this._defaults = defaults
        this._name = 'cloner'

        this.init()
    }

    static SILENT = true;

    static positions = ['after', 'before', 'append', 'prepend'];

    init() {
        this.counter = -1 // for integer indexing

        this.$emmiter.on({
            'add': (event, trigger) => this.add($(trigger).data('cloner-template'), { button: trigger }),
            'remove': (event, button) => this.remove(button),
            'removeExisting': (event, button) => this.removeExisting(button)
        })

        this.scope.addEventListener('fm:select', this.handleFmSelect)

        this.$target = this.$scope.find(this.options.target)

        this.check()

        return this
    }

    getTemplates() {
        const templates = {}

        let $templates = this.$scope.find(this.options.template)
        if (!$templates.length) {
            $templates = $(this.options.template)
        }

        $templates.each((index, template) => {
            let $template = $(template)

            // separate html and script tags
            if ($template.prop('tagName').toLowerCase() === 'script') {
                templates[$template.data('cloner-template-name') || '_default'] = $template.text()
            } else {
                $template.remove().removeClass('template')
                templates[$template.data('cloner-template-name') || '_default'] = $template[0].outerHTML
            }
        })
        return templates
    }

    handleFmSelect = event => {
        if (event.defaultPrevented) {
            return
        }

        this.add(event.target.dataset.clonerTemplate, {
            data: event.detail
        })
    }

    emmit(eventType, args) {
        this.$emmiter.trigger(eventType, args)
        return this
    }

    off() {
        this.$scope.off('.cloner')
        return this
    }

    add(templateName = '_default', options = {}) {
        const { data, silent = false, button = null } = options
        let template = this.getTemplates()[templateName]
        const regexp = new RegExp('{' + this.options.indexName + '}', 'g')
        let position = $.inArray(this.options.position, Cloner.positions) ? this.options.position : 'append'
        let $target = this.$target
        let elements = []

        if (button && typeof button.dataset.clonerItemAddAfter !== 'undefined') {
            position = 'before'
            $target = this.getItem(button)
        }

        if (this.options.indexType === 'time') {
            template = template.replace(regexp, Date.now())
        }

        if (this.options.indexType === 'integer') {
            template = template.replace(regexp, this.counter--)
        }

        if (Array.isArray(data)) {
            data.forEach(item => elements.push($(substitute(template, item))))
        } else if (data && typeof data === 'object') {
            elements.push($(substitute(template, data)))
        } else {
            elements.push($(template))
        }

        $target[position](...elements)

        this.$scope.trigger('cloneradd', elements)
        this.$scope.trigger('clonerchange')
        elements.forEach($element => $element.trigger('dom:change'))

        triggerEvent(document, 'cloneradd', {
            ctx: this.$scope[0]
        })

        if (!silent) this.check()

        return this
    }

    getItem(button) {
        return $(button).closest(this.options.item)
    }

    remove(button) {
        const message = $(button).data('cloner-confirm') || this.options.confirm
        const $item = this.getItem(button)


        if (confirm(message)) {
            triggerEvent(this.$scope[0], 'change')
            this.$scope.trigger('clonerremove', $item.detach())
            this.$scope.trigger('clonerchange')

            triggerEvent(document, 'clonerremove', {
                ctx: this.$scope[0]
            })
        } else {
            this.$scope.trigger('clonercancel', $item)
        }

        this.check()

        return this
    }

    removeExisting(button) {
        const message = $(button).data('cloner-confirm') || this.options.confirm
        const $item = this.getItem(button)

        if (confirm(message)) {
            // IE 8 can't dynamicaly replace type attribute
            let $input = $item.find('input[name]')
            $input.replaceWith($('<input type="hidden" value="1" name="' + $input.attr('name') + '">'))
            $item.hide()
            this.$scope.trigger('clonerremove', $item)
            this.$scope.trigger('clonerchange')
        } else {
            this.$scope.trigger('clonercancel')
        }

        return this
    }

    check() {
        const count = this.$target.find(this.options.item).length

        this.$scope.toggleClass('is-empty', !count)

        if (this.options.min) {
            if (count > this.options.min) {
                this.$scope.removeClass('cloner-min')
            } else {
                this.$scope.addClass('cloner-min')
            }

            // auto add new item if min is set
            if (count < this.options.min) {
                for (let i = 0; i < this.options.min; i++) {
                    this.add(null, { silent: Cloner.SILENT })
                }
            }
        }

        if (this.options.max) {
            if (count < this.options.max) {
                this.$scope.removeClass('cloner-max')
            } else {
                this.$scope.addClass('cloner-max')
            }
        }

        var $count = this.$scope.find('[data-cloner-count]')
        if ($count.length != 0) {
            $count.val(count)
        }

        return this
    }

}


// jquery plugin
$.fn[pluginName] = function (options) {
    const dataPrefixLength = dataPrefix.length

    return this.each(function () {
        if (!$.data(this, 'plugin-' + pluginName)) {

            // parse data-options
            let data = $(this).data()
            let options = {}

            // pass options in main data-attribute
            if (pluginName in data && $.type(data[pluginName]) === 'object') {
                options = data[pluginName]
            }

            // pass all options via additional data-attributes
            $.each(data, function (key, value) {
                if (key.substr(0, dataPrefixLength) === dataPrefix && key !== dataPrefix) {
                    let id = key.substr(dataPrefixLength)
                    id = id.substr(0, 1).toLowerCase() + id.substr(1)
                    options[id] = value
                }
            })

            if ('min' in options) {
                options.min = parseInt(options.min, 10)
            }

            if ('max' in options) {
                options.max = parseInt(options.max, 10)
            }

            $.data(this, 'plugin-' + pluginName, new Cloner(this, options))
        }
    })
}

$.fn[pluginName].defaults = defaults

$(document)
    .on('click.cloner', '[data-cloner-action]', function (event) {
        event.preventDefault()
        const $this = $(this)
        const cloner = $this.closest('[data-cloner]').cloner().data('plugin-cloner')

        cloner.emmit($this.data('cloner-action'), $this)
    })
    .on('dom:change', event => {
        $('[data-cloner]', event.target).cloner()
    })

$('[data-cloner]').cloner()




function triggerEvent(
    element,
    eventType,
    params = null,
    options = {
        bubbles: true,
        cancelable: true,
        detail: null
    }) {
    options.detail = params
    const event = new CustomEvent(eventType, options)
    element.dispatchEvent(event)
}