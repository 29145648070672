(function() {

    var settingTemplate = '<span class="filter-settings-item"><strong>{label}:</strong> <span>{value}</span></span>';

    function getValues($scope) {
        var items = $scope.find('.form-group').toArray().map(function(item) {
            var $item = $(item);
            var $input = $item.find('.form-control:not([type="hidden"])');
            var $itemSelector = $item.find('.item-selector');
            var value = null;

            if ($itemSelector.length) {
                var itemSelector = $itemSelector.data('plugin-giant-item-selector');
                if (itemSelector.cache) {
                    value = Object.keys(itemSelector.cache.items).map(key => itemSelector.cache.items[key].title).join(', ');
                }
            }

            if ($input.length) {
                if ($input[0].tagName.toLowerCase() === 'select') {
                    $input = $input.find(':selected');
                    value = $input.val() ? $input.text() : '';
                } else {
                    value = $input.val();
                }
            }

            if (value === null) {
                return null;
            }

            return {
                label: $item.find('.control-label').text().replace(':', ''),
                value: value
            }
        }).filter(function(item) {
            return item && item.value !== '';
        });

        return items;
    }

    $(document).on({
        'show.bs.collapse': function(event) {
            var $target = $(event.target);
            if ($target.hasClass('filter-content')) {
                $('#filter-wrapper .filter-settings').fadeOut();
            }

        },
        'hide.bs.collapse': function(event) {
            var $target = $(event.target);
            if ($target.hasClass('filter-content')) {
                var $filter = $('#filter-wrapper');
                var $settings = $filter.find('.filter-settings');
                var buffer = '';
                getValues($filter).forEach(function(item) {
                    buffer += $.substitute(settingTemplate, item);
                });
                $settings.html(buffer).fadeIn();
            }
            $('#filter-wrapper .filter-settings').fadeIn();
        }
    });

    $(document).on('click', '#filter-toggle', function() {
        var $filter = $('#filter-wrapper');
        var $settings = $filter.find('.filter-settings');

        if (getValues($filter).length) {
            $filter.find('.box-content-wrapper').collapse('toggle');
        } else {
            $filter.collapse('toggle');
        }
    });

    $('#form-grid').on('itemselectorchange', function() {
        $(this).submit();
    });

})();