import $ from 'jquery';
import createPlugin from '../../js/utils/createPlugin';
import toUrl from './toUrl';

const pluginName = 'autoUrl';
const dataPrefix = 'url';
const defaults = {
    prefix: '', // element id prefix
    mask: '', //
    validate: false,
    delay: 400,
    editable: true
};

export default class AutoUrl {

    constructor(element, options) {
        this.scope = element;
        this.$scope = $(element);

        this.options = {
            ...defaults,
            ...options
        }

        this._defaults = defaults;

        this.editable = true;
        this.items = {};
        this.timer = null;
        this.parts = [];

        this.init();
    }

    init() {
        this.parts = [];
        const rxp = /{([^}]+)}/g;

        let curMatch;

        while(curMatch = rxp.exec(this.options.mask)) {
            this.parts.push(curMatch[1]);
        }

        this.items = {};
        this.timer = null;
        this.editable = true;

        if (!this.options.editable && this.scope.value.trim() != ''){
            this.editable = false;
        }

        this.attach();

        return this;
    }

    attach() {
        this.$scope
            .on('focus.autoUrl', () => this.editable = false)
            .on('keyup.autoUrl', ::this.generate);

        this.parts.forEach(part => {
            const $element = $(`#${this.options.prefix.part}, [name="${part}"]`);

            switch ($element.prop('tagName').toLowerCase()) {
                case 'select':
                    $element.on('change.autoUrl', ::this.generate);
                break;
                default:
                    $element.on('keyup.autoUrl', ::this.generate);
            }

            this.items[part] = $element;
        });

        return this;
    }

    detach() {
        this.$scope.off('.autoUrl');
        Object.keys(self.items).forEach(key => this.items[key].off('.autoUrl'));
        return this;
    }

    generate() {
        if (this.editable){
            let rawUrl = this.options.mask;
            Object.keys(this.items).forEach(key => {
                rawUrl = rawUrl.replace('{' + key + '}', this.items[key].val());
            });
            this.scope.value = toUrl(rawUrl);
        }

        if (this.options.validate){
            this.validate();
        }

        return this;
    }

    validate() {
        clearTimeout(this.timer);
        this.timer = setTimeout(::this.request, this.options.delay);
        return this;
    }

    request() {
        $.ajax({
            url: this.options.validate,
            dataType: 'json',
            beforeSend: () => {
                this.$scope.addClass('working');
            },
            success: response => {
                if (response && 'url' in response) {
                    if (this.scope.value !== response.url) {
                        this.scope.value = response.url;
                    }
                }
            },
            complete: () => {
                self.$scope.removeClass('working');
            }
        });
    }
}

createPlugin(AutoUrl, {
    defaults: defaults,
    name: pluginName,
    prefix: dataPrefix,
    parse: settings => ({mask: settings})
});