var modalWindowTemplate = '\
    <div class="modal fade" tabindex="-1" role="dialog">\
      <div class="modal-dialog">\
        <div class="modal-content">\
          <div class="modal-header">\
            <button type="button" data-action="cancel" class="close"><span aria-hidden="true">&times;</span></button>\
            <h4 class="modal-title">Modal title</h4>\
          </div>\
          <div class="modal-body">\
          </div>\
          <ul class="modal-selected-items">\
          </ul>\
          <div class="modal-footer">\
          </div>\
        </div>\
      </div>\
    </div>';

var ModalWindow = function(options) {
    this.init(options);

    return this;
}

ModalWindow.prototype = {

    options: {
        title: null,
        content: null,
        className: 'item-selector-modal',
        offset: 0,
        fit: false, // height, width, both, false
        url: null, // automaticaly set content and open url
        buttons: [
            {
                className: 'btn',
                text: 'Zrušit',
                action: 'cancel'
            },
            {
                className: 'btn btn-primary',
                text: 'Zvolit',
                action: 'save'
            }
        ],
        onOpen: function(){},
        onBeforeOpen: function() {},
        onClose: function(){},
        onBeforeClose: function() {},
        onSave: function() {},
        onCancel: function() {}
    },

    init: function(options){
        var self = this;

        this.options = $.extend(this.options, options);

        this.build();

        if (this.options.title) { this.setTitle(this.options.title); }
        if (this.options.content) { this.setContent(this.options.content); }
        if (this.options.className) { this.$scope.addClass(this.options.className); }
        if (this.options.url) { this.openUrl(this.options.url); }

        return this;
    },

    build: function() {
        var self = this;

        this.$scope = $(modalWindowTemplate);
        this.$header = this.$scope.find('.modal-header');
        this.$title = this.$scope.find('.modal-title');
        this.$content = this.$scope.find('.modal-body');
        this.$footer = this.$scope.find('.modal-footer');
        this.$dialog = this.$scope.find('.modal-dialog');

        this.options.buttons.forEach(function(button){
            $('<button></button>')
                .attr('type', button.type)
                .addClass(button.className)
                .html(button.text)
                .on('click', self[button.action].bind(self))
                .appendTo(self.$footer);
        });

        this.$header.on('click', '[data-action=cancel]', function(event) {
            event.preventDefault();
            self.cancel();
        });

        return this;
    },

    setTitle: function(html){
        this.$title.html(html).trigger('change.title.modal');
        return this;
    },

    setContent: function(html){
        this.$content.html(html).trigger('change.content.modal');
        return this;
    },

    openUrl: function(url){
        this.setContent('<iframe frameborder="0" src="' + this.options.url + '"></iframe>');
        this.open();
        return this;
    },

    open: function(){
        this.options.onBeforeOpen.apply(this);
        this.$scope.modal('show');
        if (this.options.fit) {
            this.resize();
        }
        this.options.onOpen.apply(this);
        return this;
    },

    close: function(){
        this.options.onBeforeClose.apply(this);
        this.$scope.modal('hide');
        this.options.onClose.apply(this);
        return this;
    },

    cancel: function(){
        this.options.onCancel.apply(this);
        this.close();
        return this;
    },

    save: function(){
        this.close();
        this.options.onSave.apply(this);
        return this;
    },

    resize: function(){
        if (this.options.fit === 'height' || this.options.fit === 'both') {
            this.$scope.addClass('modal--fit-height');
        }

        // if (this.options.fit === 'width' || this.options.fit === 'both') {
        //     $(this.element).css({
        //         left: this.options.offset,
        //         right: this.options.offset,
        //         'margin-left': 0,
        //         'margin-right': 0
        //     });
        // }

        //this.fireEvent('sizeChange', this.element.getSize());
        return this;
    }

};

var pluginName = 'giantItemSelector',
    dataPrefix = 'itemSelector',
    $window = $(window),
    defaults = {
        target: 'tbody',
        item: 'tr',
        title: 'Vyberte položky',
        fit: 'height',
        selector: '.col-name a',
        multiple: true,
        getData: function(element) {
            var $element = $(element);
            var $root = $element.closest('[data-id]');
            var id = $root.data('id');
            var selectData = $root.data('select-data');

            if (typeof selectData !== 'object') {
                selectData = {};
            }

            if (id) {
                return $.extend({
                    id: id,
                    title: $element.text()
                }, selectData);
            }

            return null;
        },
        itemTemplate: '<div class="item" data-id="{id}">{title} <span class="remove">&times;</span></div>'
    };

function ItemSelector(element, options) {
    this.scope = element;
    this.$scope = $(element);

    this.options = $.extend({}, defaults, options);

    this._defaults = defaults;
    this._name = pluginName;

    this.init();
}

ItemSelector.prototype = {

    init: function() {
        var self = this;

        this.$target = this.$scope.find(this.options.target);
        this.template = this.$scope.find('.template').detach().removeClass('template').prop('outerHTML');
        this.built = false;

        this.attach();

        return this;
    },

    attach: function() {
        var self = this;

        this.$scope.on('click.itemSelector', '[data-action]', function(event) {
            event.preventDefault();
            var $trigger = $(this);

            switch ($trigger.data('action')) {
                case 'remove':
                    var message = $trigger.data('action-confirm');
                    if (!message || (message && confirm(message))) {
                        self.remove($trigger.closest(self.options.item));
                    }
                break;

                case 'cancel':
                    self.close();
                break;

                case 'edit':
                    self.open(this.href);
                break;
            }
        });

        return this;
    },

    detach: function() {
        this.$scope.off('.itemSelector');
        return this;
    },

    remove: function($item) {
        $item.remove();
        return this;
    },

    build: function() {
        var self = this;
        this.modal = new ModalWindow({
            title: this.options.title,
            className: this.options.className,
            fit: this.options.fit,
            onOpen: self.loadItems.bind(self),
            onCancel: function() {
                self.cache = {};
            },
            onBeforeClose: function(){
                //self.detachEvents(self.$frame);
            },
            onSave: function() {
                self.update();
            }
        });

        this.modal.setContent('<iframe frameborder="0" src="' + this.options.url + '"></iframe>');
        this.$frame = this.modal.$content.find('iframe');
        this.$frame.on('load', function() {
            self.attachEvents(self.$frame);

            // this.contentWindow.window.ItemSelectorBuffer.setData(self.getSelectedItems());
        });

        this.$selectedItems = this.modal.$scope.find('.modal-selected-items');

        this.$selectedItems.on('click.itemselector', '.remove', function(event) {
            self.removeItem($(this).closest('[data-id]').data('id'));
        });

        this.built = true;
    },

    open: function(url) {
        if (url) {
            this.options.url = url;
        }

        if (!this.built) {
            this.build();
        }

        this.modal.open();
    },

    getSelectedItems: function(){
        var $items = this.$target.find(this.options.item);
        var data = {
            items: {},
            order: []
        };
        $items.each(function(){
            var $item = $(this);
            var id = parseInt($item.find('input').val());
            var title = $item.text().trim();
            data.order.push(id);
            data.items[id] = {id: id, title: title};
        });
        return data;
    },

    update: function(){
        var buffer = '';
        this.cache.order.forEach(function(key) {
            buffer += $.substitute(this.template, this.cache.items[key]);
        }, this);

        this.$target.html(buffer);
        this.$scope.trigger('itemselectorchange');
        return this;
    },

    addItem: function(data) {
        if (data.id in this.cache.items) {
            var $item = this.$selectedItems.find('[data-id="' + data.id + '"]');
            if ($item.length) {
                $item.addClass('highlight');
                setTimeout(function() {
                    $item.removeClass('highlight');
                }, 500);
            }
        } else {
            if (!this.options.multiple) {
                this.cache.items = {};
                this.cache.order = [];
            }
            this.cache.items[data.id] = data;
            this.cache.order.push(data.id);
            this.renderItems();
        }
    },

    removeItem: function(id) {
        if (id in this.cache.items) {
            delete this.cache.items[id];
            var index = this.cache.order.indexOf(id);
            if (index > -1) {
                this.cache.order.splice(index, 1);
            }
            this.renderItems();
        }
    },

    loadItems: function() {
        this.cache = this.getSelectedItems();
        this.renderItems();

        return this;
    },

    renderItems: function() {
        var buffer = '';
        var self = this;

        this.cache.order.forEach(function(id) {
            buffer += $.substitute(self.options.itemTemplate, self.cache.items[id]);
        });
        this.$selectedItems.html(buffer);

        return this;
    },

    attachEvents: function($frame) {
        var self = this;
        $($frame[0].contentDocument).on('click.itemselector', this.options.selector, function(event) {
            event.preventDefault();
            var data = self.options.getData(this);
            self.addItem(data);
        });
    },

    detachEvents: function($frame) {
        $($frame[0].contentDocument).off('.itemselector');
    }

};

$.fn[pluginName] = function (options) {
    var dataPrefixLength = dataPrefix.length;

    return this.each(function () {
        if (!$.data(this, 'plugin-' + pluginName)) {

            // parse data-options
            var data = $(this).data(),
                options = {},
                settings = '';

            if (pluginName in data) {
                settings = data[pluginName];
                // pass options
                if ($.type(settings) === 'object') {
                    options = settings;
                }
            }

            // pass all options to plugin
            $.each(data, function(key, value) {
                if (key.substr(0, dataPrefixLength) === dataPrefix && key !== dataPrefix) {
                    var id = key.substr(dataPrefixLength);
                    id = id.substr(0,1).toLowerCase() + id.substr(1);
                    options[id] = value;
                }
            });

            $.data(this, 'plugin-' + pluginName, new ItemSelector(this, options));
        }
    });
};

$.fn[pluginName].defaults = defaults;

// listen to change event and init self
$(document).on('dom:change', function(event) {
    $('[data-item-selector]', event.target).giantItemSelector();
});
$('[data-item-selector]').giantItemSelector();

export default ItemSelector;