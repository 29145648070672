var timer = null;

const defaults = {
    ajax: false
}

function submitForm(node, options = {}) {
    options = {
        ...defaults,
        ...options
    }
    var $form = $(node).closest('form');
    var form = $form[0];

    if ($form.hasClass('ajax') || options.ajax) {
        $.nette.ajax({
            url: form.action,
            method: form.method || 'GET',
            dataType: 'json',
            data: $form.serialize()
        });
    } else {
        $form.submit();
    }
}


$(document)
    .on('change', 'select[data-giant-autosubmit], form[data-giant-autosubmit] input, form[data-giant-autosubmit] select', function () {
        submitForm(this, {
            ajax: this.dataset.giantAutosubmit === 'ajax'
        });
    })
    .on('click', 'input[type=radio][data-giant-autosubmit]', function () {
        submitForm(this);
    })
    .on('keyup', 'input[data-giant-autosubmit], textarea[data-giant-autosubmit], form[data-giant-autosubmit] input, form[data-giant-autosubmit] textarea', function () {
        clearTimeout(timer);
        timer = setTimeout(() => submitForm(this), 200);
    })
    .on('autosubmit.treeselector', function(event) {
        submitForm(event.target)
    })
