import $ from 'jquery';

const pluginName = 'showIf';
const dataPrefix = 'show-if';
const defaults = {
    field: null,
    value: '',
    valueType: 'string',
    multiple: false,
    detach: false, // detaches children when not visible
    condition: '==' // '==', '!='
};

export default class ShowIf {
    constructor(element, options) {
        this.scope = element;
        this.$scope = $(element);

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    init() {
        var self = this;

        if (this.options.field === null) {
            return;
        }

        // jquery fails on {} in id attribute
        var field = document.getElementById(this.options.field.replace('#',''));

        if (!field) {
            field = $('[name="' + this.options.field + '"]');
        }

        this.$field = $(field);

        if (this.$field.attr('multiple')) {
            this.options.multiple = true
        }

        this.$field.on('change ifChanged', self.render.bind(self));

        if (!$.isArray(this.options.value)) {
            this.options.value = [this.options.value];
        }

        this.options.value = this.options.value.map(::this.formatValue);

        this.$buffer = null;
        this.$parent = null;

        this.render();

        return this;
    }

    render() {
        let resolution = false;
        let fieldValue = null;

        if (this.options.multiple) {
            fieldValue = this.$field.val().map(::this.formatValue)
        } else {
            fieldValue = this.formatValue(this.$field.val())
        }

        if (this.$field.attr('type') === 'checkbox') {
            if (this.$field.length > 1) {
                fieldValue = this.$field.filter(':checked').toArray().map(element => element.value);
            } else {
                fieldValue = this.$field.is(':checked') ? 'checked' : '';
            }
        }

        if (this.$field.attr('type') === 'radio') {
            fieldValue = this.$field.filter(':checked').val();
        }

        switch (this.options.condition) {
            case '==':
                if (this.options.multiple || Array.isArray(fieldValue)) {
                    resolution = fieldValue.some(value => this.options.value.indexOf(value) > -1)
                } else {
                    resolution = this.options.value.indexOf(fieldValue) > -1 ? true : false;
                }
            break;

            case '!=':
                if (this.options.multiple) {
                    resolution = !fieldValue.some(value => this.options.value.indexOf(value) > -1)
                } else {
                    resolution = this.options.value.indexOf(fieldValue) > -1 ? false : true;
                }
            break;

            default:
                throw new Error('ShowIf: Unknown condition');
        }

        if (resolution) {
            this.show();
        } else {
            this.hide();
        }
    }

    attach() {
        if (this.$buffer !== null) {
            this.$buffer.appendTo(this.$parent);
            this.$buffer = null;
            this.$scope.trigger('dom:change');
        }
        return this;
    }

    detach() {
        if (this.$buffer === null) {
            if (this.$parent === null) {
                this.$parent = this.$scope.wrap('<div>').parent();
            }
            this.$buffer = this.$parent.children().detach();
        }
        return this;
    }

    show() {
        if (this.options.detach) {
            this.attach();
        }
        this.$scope.show();
        return this;
    }

    hide() {
        this.$scope.hide();
        if (this.options.detach) {
            this.detach();
        }
        return this;
    }

    formatValue(value) {
        var response = value;
        switch (this.options.valueType) {
            case 'integer':
                response = parseInt(value, 10);
            break;
            case 'boolean':
                response = !!value;
            break;
            default: // string
                response = value + '';
        }

        return response;
    }
}


// jquery
$.fn[pluginName] = function (options) {
    const dataPrefixLength = dataPrefix.length;

    return this.each(function () {
        if (!$.data(this, 'plugin-' + pluginName)) {

            // parse data-options
            var data = $(this).data(),
                options = {},
                settings = '';

            if (pluginName in data) {
                settings = data[pluginName];
                // pass options
                if ($.type(settings) === 'object') {
                    options = settings;
                }

                // parse shortcut and create options
                if ($.type(settings) === 'string' && settings !== '') {
                    var params = data[pluginName].match(/(.*)(!=|==)(.*)/);
                    options.field = params[1];
                    options.condition = params[2];
                    options.value = params[3].split(',');
                }

            }

            // pass all options via additional data-attributes
            $.each(data, function(key, value) {
                if (key.substr(0, dataPrefixLength) === dataPrefix && key !== dataPrefix) {
                    let id = key.substr(dataPrefixLength);
                    id = id.substr(0,1).toLowerCase() + id.substr(1);
                    options[id] = value;
                }
            });

            $.data(this, 'plugin-' + pluginName, new ShowIf(this, options));
        }
    });
};

$.fn[pluginName].defaults = defaults;

// listen to change event and init self
$(document).on('dom:change', function(event) {
    $('[data-show-if]', event.target).showIf();
});
$('[data-show-if]').showIf();
