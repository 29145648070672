/**
 * Wrapper around bootstrap datepicker to provide automatic data-binding and data-configuration.
 */
var Giant = Giant || {};

;(function($, window, document, undefined) {

    var pluginName = 'giantDatepicker',
        $window = $(window),
        defaults = {
            language: document.documentElement.lang || 'en',
            addon: '.input-group-addon',
            autoclose: true
        };

    function Datepicker(element, options) {
        this.scope = element;
        this.$scope = $(element);

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    Datepicker.prototype = {

        init: function() {
            var self = this;

            this.$scope.datepicker(this.options);
            this.$addon = this.$scope.next(this.options.addon);
            this.attach();
            return this;
        },

        attach: function() {
            var self = this;
            this.$addon.on('click.giantDatepicker', function() {
                self.$scope.focus();
            });
            return this;
        },

        detach: function() {
            this.$addon.off('.giantDatepicker');
            return this;
        }
    };

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'plugin-' + pluginName)) {

                // parse data-options
                var data = $(this).data(),
                    options = {},
                    settings = '';

                if (pluginName in data) {
                    settings = data[pluginName];
                    // pass options
                    if ($.type(settings) === 'object') {
                        options = settings;
                    }
                }

                $.each(defaults, function(key, value) {
                    var option = pluginName + key.substr(0,1).toUpperCase() + key.substr(1);

                    if (option in data) {
                        options[key] = data[option];
                    }
                });

                $.data(this, 'plugin-' + pluginName, new Datepicker(this, options));
            }
        });
    };

    $.fn[pluginName].defaults = defaults;

    // listen to change event and init self
    $(document).on('dom:change', function(event) {
        $('[data-datepicker]', event.target).giantDatepicker();
    });
    $('[data-datepicker]').giantDatepicker();

    Giant.Datepicker = Datepicker;

})(jQuery, window, document);